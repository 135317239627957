import { Col, Image, Row, Space, Tooltip } from "antd";
import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { getSolutionCateApi, getSolutionListApi } from "@/services/app/AppController";
import ImagePlaceholder, { fallback } from "@/components/ImagePlaceholder";
import { useImmer } from "use-immer";
import { useNavigate } from "react-router-dom";

interface SolutionState {
    solutionId: number | string;
    solutionName: string;
    summary: string;
    icon: string;
    image: string;
    description: string;
    solutionType: string;
}

interface TabsState {
    label: string;
    value: string;
    anchor: string;
    children: SolutionState[];
    height: number;
    desc: string;
}

interface CateState {
    id: number | string;
    solutionTypeName: string;
    solutionTypeSummary: string;
}
export default function Solution() {
    const navigate = useNavigate();
    const BASEURL = process.env.REACT_APP_BASEURL;
    const [tabs, setTabs] = useImmer<TabsState[]>([
        {
            label: "砂石骨料整体解决方案",
            value: "1",
            anchor: "anchor1",
            children: [],
            height: 0,
            desc: "",
        },
        {
            label: "干粉砂浆整体解决方案",
            value: "2",
            anchor: "anchor2",
            children: [],
            height: 0,
            desc: "",
        },
        {
            label: "水泥厂整体解决方案",
            value: "3",
            anchor: "anchor3",
            children: [],
            height: 0,
            desc: "",
        },
        {
            label: "商砼站整体解决方案",
            value: "4",
            anchor: "anchor4",
            children: [],
            height: 0,
            desc: "",
        },
    ]);
    const [tabIndex, setTabIndex] = useState<string>();
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [show, setShow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingDown = currentScrollPos > prevScrollPos;

            setPrevScrollPos(currentScrollPos);
            // 处理滚动事件
            if (isScrollingDown) {
                if (currentScrollPos > 120) {
                    setShow(false);
                }
            } else {
                setShow(true);
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    useEffect(() => {
        function getOffsetTop(id: string) {
            const element = document.getElementById(id);
            return element?.offsetTop;
        }
        // tabs.forEach((em) => getOffsetTop(em.anchor));
        setTabs((draft) => {
            draft.forEach((em) => {
                em.height = getOffsetTop(em.anchor) ?? 0;
            });
        });
    }, [tabs, setTabs]);

    useEffect(() => {
        setTabIndex("1");
        async function getData() {
            const cateList: CateState[] = await getCate();
            const { success, rows } = await getSolutionListApi();
            if (success) {
                const data: SolutionState[] = rows;
                setTabs((draft) => {
                    if (cateList.length) {
                        cateList.slice(0, 4).forEach((em, i) => {
                            draft[i].label = em.solutionTypeName;
                            draft[i].value = em.id.toString();
                            draft[i].desc = em.solutionTypeSummary;
                        });
                    }
                    draft[0].children = data.filter((em) => em.solutionType === draft[0].value);
                    draft[1].children = data.filter((em) => em.solutionType === draft[1].value);
                    draft[2].children = data.filter((em) => em.solutionType === draft[2].value);
                    draft[3].children = data.filter((em) => em.solutionType === draft[3].value);
                });
            }
        }

        async function getCate() {
            const { success, rows } = await getSolutionCateApi();
            if (success) {
                return rows;
            } else {
                return [];
            }
        }
        getData().then();
    }, [setTabs]);

    return (
        <>
            <div className={["picture", styles.picture].join(" ")}></div>

            <Row className={"main xl:py-20 xs:py-10"}>
                <Col
                    span={24}
                    className={
                        "mb-8 flex justify-between z-[20] bg-white sticky transition-all ease-linear duration-300"
                    }
                    style={{ top: show ? "120px" : 0 }}
                >
                    {tabs.map((item, index) => (
                        <Space
                            key={item.value}
                            direction={"vertical"}
                            onClick={() => {
                                setTabIndex(item.value);
                                if (prevScrollPos < tabs[index].height) {
                                    window.scrollTo(0, tabs[index].height - 74);
                                } else {
                                    window.scrollTo(0, tabs[index].height - 194);
                                }
                            }}
                            className={`tab ${tabIndex === item.value ? "active" : ""}`}
                        >
                            {item.label}

                            <div
                                className={`line ${tabIndex === item.value ? "active" : ""}`}
                            ></div>
                        </Space>
                    ))}
                </Col>

                {tabs.map((item) => (
                    <div key={item.value} className={"w-full"}>
                        {!!item.children.length && (
                            <Col id={item.anchor} span={24} className={"mb-10"}>
                                <h1 className={"text-4xl py-8 font-black pb-0"}>{item.label}</h1>
                                <div className={"indent-8 text-[#666] text-base"}>{item.desc}</div>
                            </Col>
                        )}

                        <Col
                            span={24}
                            className={`flex justify-between flex-wrap product-list ${styles.productList}`}
                        >
                            {item.children.map((em) => (
                                <div
                                    key={em.solutionId}
                                    className={`product-item group ${styles.productItem}`}
                                >
                                    <div className={"img-wrap"}>
                                        <Image
                                            className={"object-cover"}
                                            fallback={fallback}
                                            placeholder={<ImagePlaceholder />}
                                            rootClassName={"img"}
                                            src={BASEURL + em.image}
                                            alt="img"
                                            preview={false}
                                        />
                                    </div>
                                    <div className={"w-full h-4 bg-green-600"}></div>
                                    <div
                                        onClick={() =>
                                            navigate("/solutionDetail?id=" + em.solutionId)
                                        }
                                        className={"product-title"}
                                    >
                                        {em.solutionName}
                                    </div>
                                    <Tooltip title={em.summary} mouseEnterDelay={0.6}>
                                        <div className={"product-desc"}>{em.summary}</div>
                                    </Tooltip>
                                </div>
                            ))}

                            <div className={"w-[280px] h-0"}></div>
                        </Col>
                    </div>
                ))}
            </Row>
        </>
    );
}
