import { FloatButton, Layout } from "antd";
import "./Layouts.css";
import { Outlet, ScrollRestoration } from "react-router-dom";
import FooterCom from "./FooterCom";
import HeaderCom from "./HeaderCom";
import { useEffect, useState } from "react";

const { Header, Content, Footer } = Layout;
export default function Layouts() {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [show, setShow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingDown = currentScrollPos > prevScrollPos;

            setPrevScrollPos(currentScrollPos);
            // 处理滚动事件
            if (isScrollingDown) {
                if (currentScrollPos > 120) {
                    setShow(false);
                }
            } else {
                setShow(true);
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    return (
        <>
            <Layout className={"bg-white"}>
                <div
                    className={
                        "fixed left-0 right-0 top-0 z-50 bg-white transition-all ease-linear duration-300"
                    }
                    style={{ top: show ? "0" : "-120px" }}
                >
                    <Header className={"header main"}>
                        <HeaderCom />
                    </Header>
                </div>
                <Content style={{ minHeight: "100vh", paddingTop: "120px" }}>
                    <Outlet />
                </Content>
                <Footer className={"bg-slate-800 px-0"}>
                    <FooterCom />
                </Footer>
            </Layout>

            <ScrollRestoration
                getKey={(location) => {
                    return location.key;
                }}
            />

            <FloatButton.BackTop />
        </>
    );
}
