import { Button, Carousel, Image, Space, theme, Tooltip } from "antd";
import { year, month, day } from "@/utils/dateFormat";
import { useEffect, useState } from "react";
import { getHomeDataApi } from "@/services/app/AppController";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder, { fallback } from "@/components/ImagePlaceholder";
import SellTopUrl from "@/assets/sell-top.png";
import SellMiddleUrl from "@/assets/sell-middle.png";
import SellBottomUrl from "@/assets/sell-bottom.png";
import styles from "./index.module.css";

interface NewsListItem {
    newsTitle: string;
    createTime: string;
    updateTime: string;
    summary: string;
    newsId: string | number;
    icon: string;
}

interface CaseListItem {
    casesName: string;
    summary: string;
}

interface SlideListItem {
    imageUrl: string;
}

interface SolutionListItem {
    solutionId: string | number;
    solutionName: string;
    summary: string;
    icon: string;
    image: string;
}

interface ProductListItem {
    productName: string;
    summary: string;
    icon: string;
    remark: string;
    description: string;
    productId: number | string;
    image: string;
}

interface InfoState {
    newsList: NewsListItem[];
    casesList: CaseListItem[];
    SlideList: SlideListItem[];
    solutionList: SolutionListItem[];
    productList: ProductListItem[];
}

const { useToken } = theme;
export default function Index() {
    const navigate = useNavigate();
    const BASEURL = process.env.REACT_APP_BASEURL;
    const [info, setInfo] = useState<InfoState>();
    const { token } = useToken();

    async function getData() {
        const { success, data } = await getHomeDataApi();
        if (success) {
            setInfo(data);
        }
    }

    useEffect(() => {
        getData().then();
    }, []);

    return (
        <>
            <Carousel autoplay={true} waitForAnimate={false} effect={"fade"}>
                {info?.SlideList?.map((em, i) => (
                    <img
                        key={i + "banner"}
                        className={"xl:h-[752px] object-cover xs:h-[147px]"}
                        src={BASEURL + em.imageUrl}
                        alt="banner"
                    />
                ))}
            </Carousel>

            <div className={"main flex items-center xs:flex-wrap xl:py-12 xs:py-6 xs:px-4"}>
                <div className={"xs:mb-4"}>
                    <h2 className={"text-3xl xl:mb-8 xs:mb-4 font-bold text-slate-800"}>
                        智慧矿山综合管理系统解决方案提供商
                    </h2>
                    <span className={"text-[#666] leading-8 text-base"}>
                        郑州云矿信息科技有限公司是⼀家专注于智慧矿⼭信息化软件开发的公司，致⼒于为矿⼭企业提供全⾯、⾼效、智能的信息化解决⽅案，帮助企业提⾼⽣产效率、降低运营成本、提升安全环保⽔平。产品涵盖了矿⼭⽣产管理、设备监控、安全管理、销售经营等多个⽅⾯，能够满⾜不同矿⼭企业的管理需求。
                    </span>
                </div>
                <Button
                    onClick={() => {
                        navigate("/about");
                    }}
                    type={"primary"}
                    className={"h-12 px-10 text-base ml-20 text-white font-medium"}
                >
                    查看详情
                </Button>
            </div>

            <div className={"bg-gray-100"}>
                <div className={"main text-center py-20 xs:py-10"}>
                    <Space direction={"vertical"} align={"center"}>
                        <span className={"text-3xl text-slate-800 font-black"}>产品中心</span>
                        <span className={"text-gray-400"}>PRODUCTS</span>
                        <span
                            className={"w-8 h-0.5 inline-block"}
                            style={{ background: token.colorPrimary }}
                        ></span>
                    </Space>
                </div>
                <div className={"main flex justify-between flex-wrap product-list"}>
                    {info?.productList?.map((em) => (
                        <div key={em.productId} className={"product-item group"}>
                            <div className={"img-wrap"}>
                                <Image
                                    className={"object-cover"}
                                    fallback={fallback}
                                    placeholder={<ImagePlaceholder />}
                                    rootClassName={"img"}
                                    src={BASEURL + em.image}
                                    alt="img"
                                    preview={false}
                                />
                            </div>
                            <div className={"w-full h-4 bg-green-600"}></div>
                            <div
                                onClick={() => navigate("/productDetail?id=" + em.productId)}
                                className={"product-title"}
                            >
                                {em.productName}
                            </div>
                            <Tooltip title={em.summary} mouseEnterDelay={0.6}>
                                <div className={"product-desc"}>{em.summary}</div>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>

            <div className={"xl:h-[400px]  bg-[url(@/assets/index_bg.jpg)] bg-center"}>
                <div
                    className={
                        "h-full flex xl:justify-between xs:justify-center xs:flex-wrap items-center main"
                    }
                >
                    <Space className={"w-[300px] xs:py-6"} direction={"vertical"} align={"center"}>
                        <span className={"text-3xl font-medium flex items-center"}>
                            <img className={`${styles.sellIcon}`} src={SellTopUrl} alt="icon" />
                            售前
                        </span>
                        <span className={"text-lg mt-6 inline-block leading-6"}>
                            专业售前工程师，分析客户痛点，根据需求，为客户量身定制解决方案。
                        </span>
                    </Space>

                    <Space className={"w-[300px]  xs:py-6"} direction={"vertical"} align={"center"}>
                        <span className={"text-3xl font-medium flex items-center"}>
                            <img className={`${styles.sellIcon}`} src={SellMiddleUrl} alt="icon" />
                            售中
                        </span>
                        <span className={"text-lg mt-6 inline-block leading-6"}>
                            专业的实施团队，丰富的项目实施经验，为项目交付保驾护航。
                        </span>
                    </Space>

                    <Space
                        className={"w-[300px] text-center xs:py-6"}
                        direction={"vertical"}
                        align={"center"}
                    >
                        <span className={"text-3xl font-medium flex items-center"}>
                            <img className={`${styles.sellIcon}`} src={SellBottomUrl} alt="icon" />
                            售后
                        </span>
                        <span className={"text-lg mt-6 inline-block leading-6"}>
                            <div>远程支持 现场支撑 </div>
                            <div>定期巡检 应急预案</div>
                        </span>
                    </Space>
                </div>
            </div>

            <div className={"main"}>
                <div className={"main text-center py-20 xs:py-10"}>
                    <Space direction={"vertical"} align={"center"}>
                        <span className={"text-3xl text-slate-800 font-black"}>解决方案</span>
                        <span className={"text-gray-400"}>SOLUTION</span>
                        <span className={"w-8 h-0.5 bg-current-600 inline-block"}></span>
                    </Space>
                </div>
                <div className={"main flex justify-between flex-wrap product-list"}>
                    {info?.solutionList?.map((em) => (
                        <div key={em.solutionId} className={"product-item group product-item-four"}>
                            <div className={"img-wrap"}>
                                <Image
                                    className={"object-cover"}
                                    fallback={fallback}
                                    placeholder={<ImagePlaceholder />}
                                    rootClassName={"img"}
                                    src={BASEURL + em.image}
                                    alt="img"
                                    preview={false}
                                />
                            </div>
                            <div className={"w-full h-4 bg-green-600"}></div>
                            <div
                                onClick={() => navigate("/solutionDetail?id=" + em.solutionId)}
                                className={"product-title"}
                            >
                                {em.solutionName}
                            </div>
                            <Tooltip title={em.summary} mouseEnterDelay={0.6}>
                                <div className={"product-desc"}>{em.summary}</div>
                            </Tooltip>
                        </div>
                    ))}
                </div>
            </div>

            <div className={"bg-white pb-20"}>
                <div className={"main text-center py-20 xs:py-10"}>
                    <Space direction={"vertical"} align={"center"}>
                        <span className={"text-3xl text-slate-800 font-black"}>新闻资讯</span>
                        <span className={"text-gray-400"}>NEWS</span>
                        <span className={"w-8 h-0.5 bg-current-600 inline-block"}></span>
                    </Space>
                </div>

                <div className={"main"}>
                    {info?.newsList?.map((em, i) => (
                        <div key={i + ""} className={"flex items-center py-3"}>
                            <Image
                                placeholder={<ImagePlaceholder />}
                                className={"object-cover"}
                                height={160}
                                fallback={fallback}
                                rootClassName={"w-[300px] h-[160px] xs:w-full  mr-8"}
                                src={BASEURL + em.icon}
                                alt={"img"}
                                preview={false}
                            />

                            <div
                                className={
                                    "flex-1 flex items-stretch xs:px-4 px-4 py-6 xs:py-0 bg-gray-100"
                                }
                            >
                                <div
                                    className={
                                        "w-[84px] h-[84px] bg-gray-200 mr-4 text-center flex flex-col justify-center items-center"
                                    }
                                >
                                    <div className={"text-2xl text-gray-500 font-medium"}>
                                        {month(em.updateTime || em.createTime)}
                                    </div>
                                    <div className={"text-sm text-slate-800"}>
                                        {year(em.updateTime || em.createTime) +
                                            "/" +
                                            day(em.updateTime || em.createTime)}
                                    </div>
                                </div>
                                <div className={"flex-1 flex flex-col justify-between py-1"}>
                                    <div
                                        onClick={() => {
                                            navigate("/detail?id=" + em.newsId);
                                        }}
                                        className={
                                            "text-base text-slate-950 hover:text-current-600 hover:font-medium cursor-pointer"
                                        }
                                    >
                                        {em.newsTitle}
                                    </div>
                                    <span className={"text-gray-500 leading-5 line-clamp-2"}>
                                        {em.summary}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}
