import styles from "./index.module.css";
import { Divider } from "antd";
// import douBanURL from '../../assets/douban.png'
// import qqZoneURL from '../../assets/qq_zone.png'
// import sinaWeiboURl from '../../assets/sina_weibo.png'
// import wechatURL from '../../assets/wechat.png'
import { useEffect, useState } from "react";
import { getNewsDetailApi } from "@/services/app/AppController";
import richImageReplaceUrl from "@/utils/richImageReplaceUrl";

export default function Detail() {
    const [info, setInfo] = useState<Record<string, any>>({});

    async function getData() {
        const id = new URLSearchParams(window.location.search).get("id");
        const { success, data } = await getNewsDetailApi(id ? id : "");
        if (success) {
            data.content = richImageReplaceUrl(data.content || "");
            setInfo(data);
        }
    }

    useEffect(() => {
        getData().then();
    }, []);

    useEffect(() => {
        const title = document.title;
        if (info && info.newsTitle) {
            document.title = info.newsTitle + "-郑州云矿科技";
        }
        return () => {
            document.title = title;
        };
    }, [info]);

    return (
        <>
            <div className={`picture ${styles.picture}`}></div>

            <div className="content main py-8 xs:px-4">
                <h1 className={"text-2xl text-slate-800"}>{info.newsTitle}</h1>
                <Divider />
                <div
                    dangerouslySetInnerHTML={{ __html: info.content }}
                    className={"leading-8 text-gray-600"}
                />
                <Divider dashed />

                {/*<div className={'flex justify-between py-8'}>*/}
                {/*    <Space direction={"vertical"} size={"large"}>*/}
                {/*        <Space align={"center"} size={"large"}>*/}
                {/*            <h1 className={"m-0 text-gray-500"}>上一篇</h1>*/}
                {/*            <span className={"cursor-pointer text-gray-500"}>*/}
                {/*                双向跨境电商，购物商城网站开发解决方案*/}
                {/*            </span>*/}
                {/*        </Space>*/}
                {/*        <Space align={"center"} size={"large"}>*/}
                {/*            <h1 className={"m-0 text-gray-500"}>下一篇</h1>*/}
                {/*            <span className={"cursor-pointer text-gray-500"}>*/}
                {/*                远程医疗软件系统平台开发解决方案分析！*/}
                {/*            </span>*/}
                {/*        </Space>*/}
                {/*    </Space>*/}

                {/*    <Space direction={'vertical'} align={'end'} size={'large'}>*/}
                {/*        <div>*/}
                {/*            <span className={'text-lg text-slate-600 font-medium'}>文章分类：</span>*/}
                {/*            <span className={'text-xs text-gray-600'}>新闻 资讯</span>*/}
                {/*        </div>*/}
                {/*        <Space align={'end'}>*/}
                {/*            <span className={'text-lg text-slate-600 font-medium'}>分享到：</span>*/}
                {/*            <Space size={'middle'}>*/}
                {/*                <img className={styles['brand-icon']} src={wechatURL} alt="icon" />*/}
                {/*                <img className={styles['brand-icon']}  src={sinaWeiboURl} alt="icon" />*/}
                {/*                <img className={styles['brand-icon']}  src={qqZoneURL} alt="icon" />*/}
                {/*                <img className={styles['brand-icon']}  src={douBanURL} alt="icon" />*/}
                {/*            </Space>*/}
                {/*        </Space>*/}
                {/*    </Space>*/}
                {/*</div>*/}
            </div>
        </>
    );
}
