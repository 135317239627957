const APP_BASEURL = process.env.REACT_APP_BASEURL?.slice(
    0,
    process.env.REACT_APP_BASEURL?.lastIndexOf("/")
);

export default function richImageReplaceUrl(context: string) {
    console.log(APP_BASEURL);
    // 添加域名
    const newContext = context.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, (match, p1) => {
        return `<img src='${p1.indexOf("http") > -1 ? p1 : APP_BASEURL + p1}' alt="img"/>`;
    });

    let newContent = newContext.replace(/<img[^>]*>/gi, function (match) {
        match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "");
        match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "");
        match = match.replace(/height="[^"]+"/gi, "").replace(/height='[^']+'/gi, "");
        return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match) {
        match = match
            .replace(/width:[^;]+;/gi, "max-width:100%;")
            .replace(/width:[^;]+;/gi, "max-width:100%;");
        return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, "");
    newContent = newContent.replace(
        /\<img/gi,
        '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"'
    );
    return newContent;
}
