import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { message as Message } from "antd";

type Result<T> = {
    success: boolean;
    code: number;
    msg: string;
    rows?: T;
    data?: T;
};

export class Request {
    instance: AxiosInstance;
    baseConfig: AxiosRequestConfig = { baseURL: process.env.REACT_APP_BASEURL, timeout: 30 * 1000 };

    constructor(config: AxiosRequestConfig) {
        this.instance = axios.create(Object.assign(this.baseConfig, config));

        this.instance.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("token") as string;
                if (token) {
                    config.headers!.Authorization = token;
                }
                return config;
            },
            (err: any) => {
                return Promise.reject(err);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                const { data } = response;
                data.success = data.code === 200;
                return data;
            },
            (error) => {
                let message;
                switch (error.response.status) {
                    case 400:
                        message = "请求错误(400)";
                        break;
                    case 401:
                        message = "未授权，请重新登录(401)";
                        // 这里可以做清空storage并跳转到登录页的操作
                        break;
                    case 403:
                        message = "拒绝访问(403)";
                        break;
                    case 404:
                        message = "请求出错(404)";
                        break;
                    case 408:
                        message = "请求超时(408)";
                        break;
                    case 500:
                        message = "服务器错误(500)";
                        break;
                    case 501:
                        message = "服务未实现(501)";
                        break;
                    case 502:
                        message = "网络错误(502)";
                        break;
                    case 503:
                        message = "服务不可用(503)";
                        break;
                    case 504:
                        message = "网络超时(504)";
                        break;
                    case 505:
                        message = "HTTP版本不受支持(505)";
                        break;
                    default:
                        message = `连接出错(${error.response.status})!`;
                }
                Message.error(message).then();
                return Promise.reject(error.response);
            }
        );
    }

    public request<T = Result<any>>(config: AxiosRequestConfig): Promise<T> {
        return this.instance.request(config);
    }

    public get<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<Result<T>>> {
        return this.instance.get(url, config);
    }

    public post<T = any>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<Result<T>>> {
        return this.instance.post(url, data, config);
    }

    public put<T = any>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<Result<T>>> {
        return this.instance.put(url, data, config);
    }

    public delete<T = any>(
        url: string,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<Result<T>>> {
        return this.instance.delete(url, config);
    }
}

export default new Request({});
