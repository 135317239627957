import styles from "./index.module.css";
import { useEffect, useState } from "react";
import { getSolutionDetailApi } from "@/services/app/AppController";
import richImageReplaceUrl from "@/utils/richImageReplaceUrl";

export default function SolutionDetail() {
    const [info, setInfo] = useState<Record<string, any>>({});

    async function getData() {
        const id = new URLSearchParams(window.location.search).get("id");
        const { success, data } = await getSolutionDetailApi(id ? id : "");
        if (success) {
            data.content = richImageReplaceUrl(data.content || "");
            setInfo(data);
        }
    }

    useEffect(() => {
        getData().then();
    }, []);

    useEffect(() => {
        const title = document.title;
        if (info && info.solutionName) {
            document.title = info.solutionName + "-郑州云矿科技";
        }
        return () => {
            document.title = title;
        };
    }, [info]);

    return (
        <>
            <div className={`picture ${styles.picture}`}></div>

            <div className="content main py-8 xs:px-4">
                <h1 className={"text-4xl text-slate-800 text-center py-10"}>{info.solutionName}</h1>
                <div
                    dangerouslySetInnerHTML={{ __html: info.content }}
                    className={"leading-8 text-gray-600"}
                />
            </div>
        </>
    );
}
