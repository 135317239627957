import styles from "./index.module.css";
import { Col, Row, Space } from "antd";
import html from "./map";
import htmlMobile from './map-mobile'
import LocationUrl from '@/assets/location.png.webp'

export default function About() {
    return (
        <>
            <div className={`picture ${styles.picture}`}></div>

            <Row className={"main xl:py-20 xs:py-10 xs:px-5"}>
                <Col span={4}>
                    <img
                        className={'xs:w-[50px] xs:h-[64]'}
                        src={LocationUrl}
                        alt="icon"
                    />
                </Col>
                <Col span={20}>
                    <div className={"text-lg mb-8"}>地址：河南省郑州市高新区大学科技园西区孵化2号楼B座1302室</div>
                    <Space className={"text-base"} size={[24, 10]} wrap={true}>
                        <span className={"mr-4"}>电话：18236927473</span>
                        <span className={"mr-4"}>电话：18603858511</span>
                        <span className={"mr-4"}>合作：15138999900</span>
                        <span className={"mr-4"}>邮箱：1662462785@qq.com</span>
                    </Space>
                </Col>
            </Row>

            <div className={"xs:hidden main xl:py-20 flex justify-center items-center overflow-hidden"}>
                <iframe
                    className={'xl:w-[110px] xl:h-[408px] border-0'}
                    title={"地图"}
                    style={{
                        width: "1100px",
                        height: "408px",
                        border: "0px",
                    }}
                    scrolling={'no'}
                    srcDoc={html}
                />
            </div>
            <div className={"xl:hidden main flex justify-center items-center overflow-hidden"}>
                <iframe
                    className={'xs:w-full xs:h-[300px] xl:w-[110px] xl:h-[408px] border-0'}
                    title={"地图"}
                    scrolling={'no'}
                    srcDoc={htmlMobile}
                />
            </div>
        </>
    );
}
