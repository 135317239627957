export function year(value: string) {
    return new Date(value).getFullYear();
}

export function month(value: string) {
    let val = new Date(value).getMonth() + 1;
    return val < 10 ? "0" + val : val;
}

export function day(value: string) {
    return new Date(value).getDate();
}
