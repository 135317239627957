import styles from "./index.module.css";
import { Button, Image, Pagination, PaginationProps, Space, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getCaseListApi } from "@/services/app/AppController";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder, { fallback } from "@/components/ImagePlaceholder";

interface CaseState {
    icon: string;
    description: string;
    casesName: string;
    casesId: number | string;
}

export default function Case() {
    const navigate = useNavigate();
    const [list, setList] = useState<CaseState[]>([]);
    const BASEURL = process.env.REACT_APP_BASEURL;
    const defaultPageSize = 6;
    const [current, setCurrent] = useState(1);

    async function getData() {
        const { success, rows } = await getCaseListApi();
        if (success) {
            setList(rows);
        }
    }

    useEffect(() => {
        getData().then();
    }, [getData]);

    const itemRender: PaginationProps["itemRender"] = (page, type, element) => {
        if (type === "prev") {
            return <Button type={"text"}>上一页</Button>;
        }
        if (type === "next") {
            return <Button type={"text"}>下一页</Button>;
        }
        return element;
    };

    return (
        <>
            <div className={[styles.picture, "picture"].join(" ")}></div>

            <div className={"main text-center xl:py-20 xs:py-5"}>
                <Space direction={"vertical"} align={"center"}>
                    <span className={"text-3xl font-black"}>案例展示</span>
                    <span className={"text-gray-400"}>CASES</span>
                    <span className={"w-8 h-0.5 bg-current-600 inline-block"}></span>
                </Space>
            </div>

            <div className={"main flex flex-wrap justify-between"}>
                {!!list.length &&
                    list
                        .slice((current - 1) * defaultPageSize, current * defaultPageSize)
                        .map((em, i) => (
                            <Space
                                key={i + ""}
                                direction={"vertical"}
                                className={`group ${styles.caseItem}`}
                                size={0}
                            >
                                <Image
                                    rootClassName={`${styles.caseImg}`}
                                    src={BASEURL + em.icon}
                                    alt={"img"}
                                    fallback={fallback}
                                    placeholder={<ImagePlaceholder />}
                                    className={`object-cover !h-full`}
                                    preview={false}
                                />
                                <h1
                                    onClick={() => navigate("/caseDetail?id=" + em.casesId)}
                                    className={`${styles.caseTitle} group-hover:text-green-600`}
                                >
                                    {em.casesName}
                                </h1>
                                <Tooltip mouseEnterDelay={0.6} title={em.description}>
                                    <span className={`${styles.caseText}`}>{em.description}</span>
                                </Tooltip>
                                <Button
                                    onClick={() => navigate("/caseDetail?id=" + em.casesId)}
                                    className={`${styles.caseLink}`}
                                    type={"link"}
                                >
                                    了解更多 &gt;
                                </Button>
                            </Space>
                        ))}

                <div className={"w-[370px] h-0"}></div>
            </div>
            <div className={"flex justify-center items-center xl:mb-20 xs:mb-10"}>
                <Button disabled={current === 1} onClick={() => setCurrent(1)} type={"text"}>
                    首页
                </Button>
                <Pagination
                    current={current}
                    total={list.length}
                    defaultPageSize={defaultPageSize}
                    onChange={(page) => {
                        setCurrent(page);
                    }}
                    itemRender={itemRender}
                />
                <Button
                    disabled={current === Math.ceil(list.length / defaultPageSize)}
                    onClick={() => setCurrent(Math.ceil(list.length / defaultPageSize))}
                    type={"text"}
                >
                    尾页
                </Button>
            </div>
        </>
    );
}
