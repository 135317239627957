const html = '<!DOCTYPE html>\n' +
    '<html xmlns="https://www.w3.org/1999/xhtml">\n' +
    '  <head>\n' +
    '    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />\n' +
    '    <meta name="keywords" content="百度地图,百度地图API，百度地图自定义工具，百度地图所见即所得工具" />\n' +
    '    <meta name="description" content="百度地图API自定义地图，帮助用户在可视化操作下生成百度地图" />\n' +
    '    <title>百度地图API自定义地图</title>\n' +
    '    <!--引用百度地图API-->\n' +
    '    <script type="text/javascript" src="https://api.map.baidu.com/api?v=2.0&ak=BGQpaDCCOMGFmsS6RfP2BTXC3XMWLz3D"></script>\n' +
    '  </head>\n' +
    '  \n' +
    '  <body style="margin: 0;">\n' +
    '    <!--百度地图容器-->\n' +
    '    <div style="width:100%;height:300px;border:#ccc solid 0px;font-size:12px" id="map"></div>\n' +
    '    <p style="color:red;font-weight:600">地图生成工具基于百度地图JS api v2.0版本开发，使用请申请密匙。\n' +
    '      <a href="https://developer.baidu.com/map/index.php?title=jspopular/guide/introduction" style="color:#2f83c7" target="_blank">了解如何申请密匙</a>\n' +
    '      <a href="https://lbsyun.baidu.com/apiconsole/key?application=key" style="color:#2f83c7" target="_blank">申请密匙</a>\n' +
    '    </p>\n' +
    '  </body>\n' +
    '  <script type="text/javascript">\n' +
    '    //创建和初始化地图函数：\n' +
    '    function initMap(){\n' +
    '      createMap();//创建地图\n' +
    '      setMapEvent();//设置地图事件\n' +
    '      addMapControl();//向地图添加控件\n' +
    '      addMapOverlay();//向地图添加覆盖物\n' +
    '    }\n' +
    '    function createMap(){ \n' +
    '      map = new BMap.Map("map"); \n' +
    '      map.centerAndZoom(new BMap.Point(113.548781,34.79918),15);\n' +
    '    }\n' +
    '    function setMapEvent(){\n' +
    '      map.enableScrollWheelZoom();\n' +
    '      map.enableKeyboard();\n' +
    '      map.enableDragging();\n' +
    '      map.enableDoubleClickZoom()\n' +
    '    }\n' +
    '    function addClickHandler(target,window){\n' +
    '      target.addEventListener("click",function(){\n' +
    '        target.openInfoWindow(window);\n' +
    '      });\n' +
    '    }\n' +
    '    function addMapOverlay(){\n' +
    '      var markers = [\n' +
    '        {content:"河南省郑州市高新区大学科技园西区孵化2号楼B座1302室",title:"郑州云矿信息科技有限公司",imageOffset: {width:-46,height:-21},position:{lat:34.799205,lng:113.548818}},\n' +
    '      ];\n' +
    '      for(var index = 0; index < markers.length; index++ ){\n' +
    '        var point = new BMap.Point(markers[index].position.lng,markers[index].position.lat);\n' +
    '        var marker = new BMap.Marker(point,{icon:new BMap.Icon("https://api.map.baidu.com/lbsapi/createmap/images/icon.png",new BMap.Size(20,25),{\n' +
    '          imageOffset: new BMap.Size(markers[index].imageOffset.width,markers[index].imageOffset.height)\n' +
    '        })});\n' +
    '        var label = new BMap.Label(markers[index].title,{offset: new BMap.Size(25,5)});\n' +
    '        var opts = {\n' +
    '          width: 200,\n' +
    '          title: markers[index].title,\n' +
    '          enableMessage: false\n' +
    '        };\n' +
    '        var infoWindow = new BMap.InfoWindow(markers[index].content,opts);\n' +
    '        marker.setLabel(label);\n' +
    '        addClickHandler(marker,infoWindow);\n' +
    '        map.addOverlay(marker);\n' +
    '      };\n' +
    '    }\n' +
    '    //向地图添加控件\n' +
    '    function addMapControl(){\n' +
    '      var scaleControl = new BMap.ScaleControl({anchor:BMAP_ANCHOR_BOTTOM_LEFT});\n' +
    '      scaleControl.setUnit(BMAP_UNIT_IMPERIAL);\n' +
    '      map.addControl(scaleControl);\n' +
    '      var navControl = new BMap.NavigationControl({anchor:BMAP_ANCHOR_TOP_LEFT,type:0});\n' +
    '      map.addControl(navControl);\n' +
    '    }\n' +
    '    var map;\n' +
    '      initMap();\n' +
    '  </script>\n' +
    '</html>'
export default  html
