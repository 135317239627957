import { Col, Image, Row, Space, Tooltip } from "antd";
import "./index.css";
import app from "@/services/app";
import { useEffect, useState } from "react";
import ProductBannerUrl from "@/assets/product_banner.jpg";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder, { fallback } from "@/components/ImagePlaceholder";
import { useImmer } from "use-immer";

const services = app.AppController;

interface ProductsState {
    productName: string;
    summary: string;
    icon: string;
    image: string;
    description: string;
    productId: number | string;
    productType: string;
}
interface TabsState {
    label: string;
    value: string;
    anchor: string;
    children: ProductsState[];
    height: number;
}
export default function Product() {
    const [tabIndex, setTabIndex] = useState<string>();
    const navigate = useNavigate();
    const BASEURL = process.env.REACT_APP_BASEURL;
    const [tabs, setTabs] = useImmer<TabsState[]>([
        {
            label: "数字孪生可视化管理系统",
            value: "1",
            anchor: "anchor1",
            children: [],
            height: 0,
        },
        { label: "生产DCS管理系统", value: "2", anchor: "anchor2", children: [], height: 0 },
        { label: "智能销售管理系统", value: "3", anchor: "anchor3", children: [], height: 0 },
    ]);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [show, setShow] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isScrollingDown = currentScrollPos > prevScrollPos;

            setPrevScrollPos(currentScrollPos);
            // 处理滚动事件
            if (isScrollingDown) {
                if (currentScrollPos > 120) {
                    setShow(false);
                }
            } else {
                setShow(true);
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    useEffect(() => {
        setTabIndex("1");
        async function getData() {
            const res = await services.getProductListApi();
            if (res.success) {
                const data: ProductsState[] = res.rows;
                setTabs((draft) => {
                    draft[0].children = data.filter((em) => em.productType === draft[0].value);
                    draft[1].children = data.filter((em) => em.productType === draft[1].value);
                    draft[2].children = data.filter((em) => em.productType === draft[2].value);
                });
            }
        }
        getData().then();
    }, [setTabs]);

    useEffect(() => {
        function getOffsetTop(id: string) {
            const element = document.getElementById(id);
            return element?.offsetTop;
        }
        // tabs.forEach((em) => getOffsetTop(em.anchor));
        setTabs((draft) => {
            draft.forEach((em) => {
                em.height = getOffsetTop(em.anchor) ?? 0;
            });
        });
    }, [tabs, setTabs]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollHeight = window.scrollY;
            const eleHeightArr = tabs.map((em) => em.height ?? 0);
            if (currentScrollHeight < eleHeightArr[0]) {
                setTabIndex("1");
            } else if (currentScrollHeight < eleHeightArr[1]) {
                setTabIndex("2");
            } else if (currentScrollHeight < eleHeightArr[2]) {
                setTabIndex("3");
            }
        };
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, [tabs]);

    return (
        <>
            <img
                className={"xl:h-[500px] xs:h-16 w-full object-cover"}
                src={ProductBannerUrl}
                alt="banner"
            />

            <Row className={"main py-10"}>
                <Col
                    span={24}
                    className={
                        "mb-8 flex justify-between z-[20] bg-white sticky transition-all ease-linear duration-300"
                    }
                    style={{ top: show ? "120px" : 0 }}
                >
                    {tabs.map((item, index) => (
                        <Space
                            key={item.value}
                            direction={"vertical"}
                            onClick={() => {
                                setTabIndex(item.value);
                                if (prevScrollPos < tabs[index].height) {
                                    window.scrollTo(0, tabs[index].height - 74);
                                } else {
                                    window.scrollTo(0, tabs[index].height - 194);
                                }
                            }}
                            className={`tab ${tabIndex === item.value ? "active" : ""}`}
                        >
                            {item.label}

                            <div
                                className={`line ${tabIndex === item.value ? "active" : ""}`}
                            ></div>
                        </Space>
                    ))}
                </Col>

                {tabs.map((item) => (
                    <div key={item.anchor} className={"w-full"}>
                        {!!item.children.length && (
                            <Col id={item.anchor} span={24}>
                                <h1 className={"text-4xl py-8 font-black"}>{item.label}</h1>
                            </Col>
                        )}

                        <Col span={24} className={"flex justify-between flex-wrap product-list"}>
                            {item.children.map((em) => (
                                <div key={em.productId} className={"product-item group"}>
                                    <div className={"img-wrap"}>
                                        <Image
                                            className={"object-cover"}
                                            fallback={fallback}
                                            placeholder={<ImagePlaceholder />}
                                            rootClassName={"img"}
                                            src={BASEURL + em.image}
                                            alt="img"
                                            preview={false}
                                        />
                                    </div>
                                    <div className={"w-full h-4 bg-green-600"}></div>
                                    <div
                                        onClick={() =>
                                            navigate("/productDetail?id=" + em.productId)
                                        }
                                        className={"product-title"}
                                    >
                                        {em.productName}
                                    </div>
                                    <Tooltip title={em.description} mouseEnterDelay={0.6}>
                                        <div className={"product-desc"}>{em.description}</div>
                                    </Tooltip>
                                </div>
                            ))}
                        </Col>
                    </div>
                ))}
            </Row>
        </>
    );
}
