import http from "@/utils/http";

export async function getHomeDataApi() {
    return http.request({
        url: "/general/all",
        method: "GET",
    });
}

/**
 *  产品列表
 */
export async function getProductListApi() {
    return http.request({
        url: "/general/product/list",
        method: "GET",
    });
}

/**
 * 产品详情
 * @param productId
 */
export async function getProductDetailApi(productId: string) {
    return http.request({
        url: `/general/product/${productId}`,
        method: "GET",
    });
}

/**
 * 解决方案分类
 */
export async function getSolutionCateApi() {
    return http.request({
        url: "/general/solutiontype/list",
        method: "GET",
    });
}

/**
 * 解决方案列表
 */
export async function getSolutionListApi() {
    return http.request({
        url: "/general/solution/list",
        method: "GET",
    });
}

/**
 * 解决方案的详情
 * @param solutionId
 */
export async function getSolutionDetailApi(solutionId: string) {
    return http.request({
        url: `/general/solution/${solutionId}`,
    });
}

/**
 * 案例列表
 */
export async function getCaseListApi() {
    return http.request({
        url: "/general/cases/list",
        method: "GET",
    });
}

/**
 * 案例详情
 * @param casesId
 */
export async function getCaseDetailApi(casesId: string) {
    return http.request({
        url: `/general/cases/${casesId}`,
        method: "GET",
    });
}

/**
 * 资讯列表
 */
export async function getNewsListApi() {
    return http.request({
        url: "/general/news/list",
        method: "GET",
    });
}

/**
 * 资讯详情
 * @param newsId
 */
export async function getNewsDetailApi(newsId: string) {
    return http.request({
        url: `/general/news/${newsId}`,
    });
}
