import React from "react";
import "antd/dist/reset.css";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./layouts/Layout";
import NoMatch from "./pages/404";
import Index from "./pages/Index";
import Product from "./pages/Product";

import Solution from "./pages/Solution";
import Case from "./pages/Case";
import News from "./pages/News";
import About from "./pages/About";
import Detail from "./pages/Detail";
import ProductDetail from "@/pages/ProductDetail/ProductDetail";
import CaseDetail from "@/pages/CaseDetail";
import SolutionDetail from "@/pages/SolutionDetail/SolutionDetail";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        errorElement: <NoMatch />,
        children: [
            { index: true, element: <Index /> },
            { path: "product", element: <Product /> },
            { path: "productDetail", element: <ProductDetail /> },
            { path: "solution", element: <Solution /> },
            { path: "solutionDetail", element: <SolutionDetail /> },
            { path: "case", element: <Case /> },
            { path: "caseDetail", element: <CaseDetail /> },
            { path: "news", element: <News /> },
            { path: "about", element: <About /> },
            { path: "detail", element: <Detail /> },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
