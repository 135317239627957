import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

export default function NoMatch() {
  const navigate = useNavigate();
  return (
    <Result
      title={"404"}
      status={"404"}
      subTitle={"对不起，您访问的页面不存在。"}
      extra={
        <Button
          onClick={() => {
            navigate("/");
          }}
          type={"primary"}
        >
          返回首页
        </Button>
      }
    />
  );
}
