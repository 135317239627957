import { Dropdown, Menu, MenuProps, Space } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoURL from "../assets/logo-green.jpg";
import MenuIconURL from "@/assets/menu_icon.png";
import MenuCloseURL from "@/assets/menu_close.png";

export default function HeaderCom() {
    const navigate = useNavigate();
    const location = useLocation();
    const [current, setCurrent] = useState("");
    const [menuFold, setMenuFold] = useState(false);
    const items: MenuProps["items"] = [
        { label: "首页", key: "/" },
        { label: "产品中心", key: "/product" },
        { label: "解决方案", key: "/solution" },
        { label: "案例展示", key: "/case" },
        { label: "资讯动态", key: "/news" },
        { label: "联系我们", key: "/about" },
    ];

    useEffect(() => {
        if (location.pathname === "/detail") {
            return setCurrent("/news");
        }
        if (location.pathname === "/productDetail") {
            return setCurrent("/product");
        }
        if (location.pathname === "/caseDetail") {
            return setCurrent("/case");
        }
        if (location.pathname === "/solutionDetail") {
            return setCurrent("/solution");
        }
        setCurrent(location.pathname);
    }, [location]);

    const onClick: MenuProps["onClick"] = (e) => {
        navigate(e.key);
        setMenuFold(false);
    };

    return (
        <>
            <Space>
                <img className={"logo"} src={LogoURL} alt="logo" />
            </Space>
            <div className={"xl:flex-1 xl:flex xl:justify-end xs:hidden"}>
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    className={"border-b-transparent menu"}
                    mode={"horizontal"}
                    theme={"light"}
                    items={items}
                />
            </div>

            <Space className={"xs:absolute right-0 xs:mr-3 xl:hidden"}>
                <Dropdown
                    open={menuFold}
                    trigger={["click"]}
                    overlayClassName={"menu-dropdown w-[150px]"}
                    onOpenChange={(open) => {
                        setMenuFold(open);
                    }}
                    className={"w-[200px]"}
                    menu={{ onClick, items, selectable: true, defaultSelectedKeys: [current] }}
                    arrow
                    placement={"bottomRight"}
                >
                    <a
                        href={"/#"}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        {!menuFold && (
                            <img className={"xs:w-7 xs:h-7"} src={MenuIconURL} alt="menu" />
                        )}
                        {menuFold && (
                            <img className={"xs:w-7 xs:h-7"} src={MenuCloseURL} alt="menu" />
                        )}
                    </a>
                </Dropdown>
            </Space>
        </>
    );
}
