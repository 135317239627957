import { Divider, Image, Space } from "antd";
import QrcodeUrl from "@/assets/qrcode.jpg";

export default function FooterCom() {
    return (
        <>
            <div className="main flex justify-between xs:flex-wrap text-white items-center py-10 xs:py-5">
                <Space
                    direction={"vertical"}
                    className={"w-[300px] xs:w-full xs:px-4"}
                    size={"middle"}
                >
                    <span>客服服务热线：</span>
                    <Space align={"center"} size={"middle"}>
                        <span className={"text-xl"}>15138999900</span>
                        <span className={"relative top-0.5"}>周一周五(9:00-18:00)</span>
                    </Space>
                    <Space wrap={true}>
                        <span>韩经理：18236927473</span>
                        <span>18603858511</span>
                    </Space>
                </Space>

                <Image src={QrcodeUrl} width={100} height={100} />

                <Divider type={"vertical"} className={"bg-gray-600 h-20 xs:hidden"} />

                <Space className={"xs:px-4 xs:mt-6"} direction={"vertical"} size={"middle"}>
                    <span className={"leading-6"}>
                        公司地址：河南省郑州市高新区大学科技园西区孵化2号楼B座1302室
                    </span>
                    <span>邮政编码：450000</span>
                    <span>客服邮箱：1662462785@qq.com</span>
                    <Space>
                        友情链接：
                        <a className={"text-white"} href={"https://www.guangdonglaisen.com"}>
                            橡塑管
                        </a>
                    </Space>
                </Space>
            </div>

            <Divider className={"bg-gray-600"} />
            <div className={"main text-center px-4"}>
                <Space
                    wrap={true}
                    size={["large", 8]}
                    className={"text-white text-base text-center"}
                    align={"center"}
                >
                    <span>豫ICP备2023015019号-1</span>
                    <span className={"xs: hidden lg:block"}>|</span>
                    <span>Copyright ©2023 郑州云矿信息科技有限公司</span>
                    <span></span>
                </Space>
            </div>
        </>
    );
}
