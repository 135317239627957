import styles from "./index.module.css";
import { Image, Space } from "antd";
import { day, month, year } from "@/utils/dateFormat";
import { useEffect, useState } from "react";
import { getNewsListApi } from "@/services/app/AppController";
import { useNavigate } from "react-router-dom";
import ImagePlaceholder, { fallback } from "@/components/ImagePlaceholder";

interface NewsState {
    newsId: string | number;
    newsTitle: string;
    createTime: string;
    updateTime: string;
    summary: string;
    icon: string;
}

export default function News() {
    const BASEURL = process.env.REACT_APP_BASEURL;
    const navigate = useNavigate();
    const [list, setList] = useState<NewsState[]>([]);

    async function getData() {
        const { success, rows } = await getNewsListApi();
        if (success) {
            setList(rows);
        }
    }

    useEffect(() => {
        getData().then();
    }, []);

    return (
        <>
            <div className={`picture ${styles.picture}`}></div>

            <div className={"main text-center xl:py-20 xs:py-5"}>
                <Space direction={"vertical"} align={"center"}>
                    <span className={"text-3xl font-black"}>资讯动态</span>
                    <span className={"text-gray-400"}>CASES</span>
                    <span className={"w-8 h-0.5 inline-block bg-current-600"}></span>
                </Space>
            </div>

            <div className="main xl:pb-20 xs:pb-10 xs:pt-5">
                {list.map((em, i) => (
                    <div key={i + ""} className={"flex items-center py-3"}>
                        <Image
                            fallback={fallback}
                            placeholder={<ImagePlaceholder />}
                            preview={false}
                            className={"object-cover"}
                            height={160}
                            src={BASEURL + em.icon}
                            rootClassName={"w-[300px] h-[160px] xs:w-full  mr-8 rounded"}
                            alt={"img"}
                        />

                        <div
                            className={
                                "flex-1 flex items-stretch xs:px-4 px-4 py-6 xs:py-0 bg-gray-100"
                            }
                        >
                            <div
                                className={
                                    "w-[84px] h-[84px] bg-gray-200 mr-4 text-center flex flex-col justify-center items-center"
                                }
                            >
                                <div className={"text-2xl text-gray-500 font-medium"}>
                                    {month(em.updateTime || em.createTime)}
                                </div>
                                <div className={"text-sm text-slate-800"}>
                                    {year(em.updateTime || em.createTime) +
                                        "/" +
                                        day(em.updateTime || em.createTime)}
                                </div>
                            </div>
                            <div className={"flex-1 flex flex-col justify-between py-1"}>
                                <div
                                    onClick={() => {
                                        navigate("/detail?id=" + em.newsId);
                                    }}
                                    className={
                                        "text-base text-slate-950 hover:text-current-600 hover:font-medium cursor-pointer"
                                    }
                                >
                                    {em.newsTitle}
                                </div>
                                <span className={"text-gray-500 leading-5 line-clamp-2"}>
                                    {em.summary}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
